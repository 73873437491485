* {
  font-family: "Comfortaa", sans-serif !important;
}

li.search {
  z-index: 1 !important;
}

body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-name input[type="password"] {
  margin: 0;
  padding: 10px 2%;
}

.tuto1 {
  z-index: 0;
}

.Toastify__close-button > svg {
  position: unset !important;
}

.Toastify__toast--success {
  background: #22bb33 !important;
}

.btn_link {
  color: white !important;
  height: 25px !important;
  width: 25px !important;
  position: static !important;
}
